import { ContentContainer, FormSubsection, MUITableD } from "components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
  data: any;
};

const SearchResult = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation("transaction"); // Get translation
  const navigate = useNavigate();

  const columns = [
    { name: "guid", label: t("GUID"), options: { display: false } },
    { name: "transactionid", label: t("Transaction ID") },
    { name: "transactionts", label: t("Timestamp") },
    { name: "transactionnumber", label: t("STAN") },
    { name: "transactiontype", label: t("Type") },
    { name: "entry", label: t("Entry Mode") },
    { name: "status", label: t("Status") },
    {
      name: "amount",
      label: t("Amount"),
      options: { customBodyRender: (value: number) => value.toFixed(2) },
    },
    {
      name: "freeamount",
      label: t(" Free Amount"),
      options: { customBodyRender: (value: number) => value.toFixed(2) },
    },
    { name: "result", label: t("Result Code") },
    { name: "resultmessage", label: t("Result Message") },
    { name: "profitcenter", label: t("Profit Center") },
    { name: "customernr", label: t("Customer") },
    { name: "outlet", label: t("Outlet") },
    { name: "serialnr", label: t("Serial") },
    { name: "deviceid", label: t("Device / Mac Address") },
    { name: "cardtype", label: t("Card Type") },
    { name: "lastfour", label: t("Last 4") },
    { name: "expiration", label: t("Expiration Date") },
    { name: "invoicenumber", label: t("Invoice") },
    { name: "batch", label: t("Batch") },
    { name: "description", label: t("Description") },
  ];

  const handleRelRowClick = (rowdata: Array<String>) => {
    navigate("/app/transactions/" + rowdata[0]);
  };

  return (
    <>
      <ContentContainer py={4}>
        <FormSubsection title={t("Transactions")}>
          <MUITableD
            title=""
            data={data}
            cols={columns}
            sortOrder={{ name: "transactionts", direction: "desc" }}
            onRowClick={handleRelRowClick}
          />
        </FormSubsection>
      </ContentContainer>
    </>
  );
};

export default SearchResult;
